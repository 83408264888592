<template>
  <div ref="track" class="main">
    <div class="top">
      <span class="top_text">物流详情</span>
      <span class="top_border"></span>
    </div>
    <div class="track_box">
      <div v-if="track_lists.length" class="content">
        <div class="trackInfo">
          <div class="left">
            <div class="info">
              <img v-if="results.product_thumb" :src="results.product_thumb" onerror="javascript:this.src='https://newoss.zhulong.com/forum/202403/05/25/183325dsqrs53owrxld2lg.png'" alt="">
              <img v-if="!results.product_thumb" src="https://newoss.zhulong.com/forum/202403/05/25/183325dsqrs53owrxld2lg.png" alt="">
              <div class="name">
                <span>{{ results.product_name }}</span>
              </div>
              <div class="track_msg">
                <span>物流公司： {{ trace_name }}</span>
              </div>
              <div v-if="results.gift_source==1&&results.express_no" class="track_msg">
                <span>物流单号： {{ results.express_no }}</span>
              </div>
              <div v-if="results.send_content" class="track_msg">
                <span>物品详情： {{ results.send_content }}</span>
              </div>
              <div v-if="results.gift_source==2&&results.express_no" class="track_msg">
                <span>订单号： {{ results.express_no }}</span>
              </div>
            </div>
          </div>
          <div class="right">
            <div v-if="track_lists !==undefined && track_lists.length > 0" class="list">
              <ul class="list_cnt">
                <li v-for="(info,index) in track_lists" :key="index">
                  <div class="msg_box">
                    <i class="icons">
                      <i v-if="index===0" class="state"></i>
                      <i v-else class="state1"></i>
                      <i v-if="index===0" class="line"></i>
                      <i v-else-if="index===(track_lists.length-1)" class="line2"></i>
                      <i v-else class="line1"></i>
                    </i>
                    <div class="text_cnt">
                      <div class="adress">
                        <span>{{ info.AcceptStation }}</span>
                      </div>
                      <div class="time">
                        <span>{{ info.AcceptTime }}</span>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <!-- <div v-else class="noDataBox">
              <img src="@/assets/image/kong.png" alt="">
              <p>当还没有快递信息哦~</p>
            </div>-->
          </div>
        </div>
      </div>
      <div v-else class="noDataBox">
        <img src="@/assets/image/kong.png" alt>
        <p>当前还没有快递信息哦~</p>
      </div>
    </div>
  </div>
</template>
<script>
import { getTrackingList, Scorelogistic } from '@/api/center.js'
export default {
  data() {
    return {
      params: {
        id: this.$route.query.id
      },
      results: {},
      track_lists: [],
      trace_name: '',
      mytype: this.$route.query.type || 1
    }
  },
  beforeCreate() {
  },
  created() {
    this.getExpressDetail()
  },
  mounted() {
  },
  methods: {
    // 物流详情
    getExpressDetail() {
      if (this.mytype === 1) {
        getTrackingList(this.params).then(res => {
          const result = res.result
          this.results = result
          this.product = result
          const express_track = result.express_track ? result.express_track : {}
          this.track_lists = express_track.Traces ? express_track.Traces : []
          this.trace_name = express_track.ExpressName ? express_track.ExpressName : ''
        // 追加一条，暂时默认添加，后期具体规则待定
        // const last = {
        //   AcceptTime: result.create_time ? result.create_time : '',
        //   AcceptStation: '您提交了订单，请等待系统确认'
        // }
        // this.track_lists.push(last)
        // console.log(this.track_lists)
        // console.log(this.trace_name)
        }).catch(err => {
          console.log(err)
        })
      } else {
        Scorelogistic(this.params).then(res => {
          const result = res.result
          this.results = result
          this.product = result
          const express_track = result.express_track ? result.express_track : {}
          this.track_lists = express_track.Traces ? express_track.Traces : []
          this.trace_name = express_track.ExpressName ? express_track.ExpressName : ''
        }).catch(err => {
          console.log(err)
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.main {
  background-color: #fff;
  position: relative;
  padding: 0 10px;
  font-family: MicrosoftYaHei;
}
.top {
  width: 100%;
  height: 55px;
  padding-top: 30px;
  padding-left: 23px;
  line-height: 20px;
  font-size: 18px;
  text-align: left;
}
.top_border {
  position: absolute;
  left: 10px;
  width: 4px;
  height: 20px;
  background: #f04e4f;
}
.content {
  position: relative;
  padding: 15px 10px;
  min-height: 500px;
}
.trackInfo {
  display: flex;
  min-height: 500px;
}
.left {
  width: 390px;
  display: flex;
  float: left;
  padding-top: 55px;
}
.info {
  float: left;
  text-align: left;
}
.info img {
  margin-left: 100px;
  width: 138px;
  height: 138px;
}
.name {
  margin-top: 35px;
  margin-left: 98px;
  font-family: MicrosoftYaHei;
  font-size: 14px;
  color: #444444;
}
.track_msg {
  margin-top: 5px;
  margin-left: 98px;
  font-family: MicrosoftYaHei;
  font-size: 12px;
  color: #999999;
}
.right {
  width: 525px;
}
.list {
  margin: 10px;
}
.list_cnt {
  text-align: left;
  position: relative;
  ul {
    list-style: none;
    position: relative;
  }
  li {
    position: relative;
  }

  .text_cnt {
    margin: 10px 0 10px 50px;
    height: auto;
    .adress {
      font-family: MicrosoftYaHei;
      font-size: 14px;
      line-height: 20px;
      color: #000000;
    }
    .time {
      margin-top: 5px;
      font-family: MicrosoftYaHei;
      font-size: 12px;
      line-height: 20px;
      color: #999999;
    }
  }
  .icons {
    top: 0;
    left: 10px;
    width: 30px;
    height: 100%;
    position: absolute;
    text-align: center;
    .state {
      display: inline-block;
      width: 22px;
      height: 22px;
      margin-top: 10px;
      overflow: hidden;
      // background-image: url('https://newoss.zhulong.com/forum/202105/06/10/1620268469419289.png');
      background: url("https://newoss.zhulong.com/forum/202105/06/10/1620268469419289.png")
        no-repeat;
    }
    .line {
      position: absolute;
      display: block;
      width: 1px;
      height: auto;
      left: 14.5px;
      top: 20px;
      bottom: -5px;
      overflow: hidden;
      background: url("https://newoss.zhulong.com/forum/202105/06/10/1620268178737541.png")
        no-repeat;
    }
    .state1 {
      display: inline-block;
      width: 9px;
      height: 9px;
      margin-top: 18px;
      overflow: hidden;
      background: url("https://newoss.zhulong.com/forum/202105/06/11/1620272512109195.png")
        no-repeat;
    }
    .line1 {
      position: absolute;
      display: block;
      width: 1px;
      height: auto;
      left: 14px;
      top: 0px;
      bottom: -5px;
      overflow: hidden;
      background: url("https://newoss.zhulong.com/forum/202105/06/10/1620268178737541.png")
        no-repeat;
    }
    .line2 {
      position: absolute;
      display: block;
      width: 1px;
      left: 14px;
      top: 0px;
      height: 18px;
      overflow: hidden;
      background: url("https://newoss.zhulong.com/forum/202105/06/10/1620268178737541.png")
        no-repeat;
    }
  }
}
.noDataBox {
  position: relative;
  padding: 6px 10px 0;
  min-height: 500px;
  width: 100%;
  min-height: 500px;
  img {
    margin-top: 140px;
    width: 140px;
    height: 140px;
  }
  p {
    margin-top: 35px;
    text-align: center;
    line-height: 20px;
  }
}
.main_bot {
  background-color: red;
}
</style>
